<template>
  <div>
    <PageTitle title="我的地址" fixed
               :backTo="$route.params.departmentId == 0 ? '/self' :$route.params.departmentId == -1?'/encore/'+$route.params.doctorId+'/-1': ('/consultOnline/1/' + $route.params.departmentId + '/' + $route.params.doctorId)"
               :leftArrow="$route.params.departmentId == 0 ? false : true"></PageTitle>

    <div style="width: 85%;margin: 15px auto;">
      <van-radio-group v-model="address">
        <List :width="100">
          <!--<ListItem class="cardInfo">-->
          <!--<template #top>-->
          <!--<p><span>就诊卡：张晓丹</span><span>社保</span><span>001910***666666</span><span><router-link to="/"><van-icon name="idcard" size="16px"/>卡包</router-link></span></p>-->
          <!--</template>-->
          <!--</ListItem>-->
          <ListItem v-for="address in userAddress" :key="address.id" :leftWidth="80" :rightWidth="20">
            <template #left>
              <van-radio :name="address.id">
                <p><b>{{ address.addressee }}</b> {{ address.phone }}</p>
                <p>{{ address.province }} {{ address.city }} {{ address.area }} {{ address.street }}</p>
                <p>{{ address.specificAddress }}</p>
              </van-radio>
            </template>
            <template #right>
              <img v-if="address.isdefault == 1" src="../assets/defaultAddress.png">
              <van-icon name="edit"
                        @click="$router.push('/addAddress/' + $route.params.departmentId + '/' + $route.params.doctorId + '/' + address.id)"/>
            </template>
          </ListItem>
          <ListItem :to="'/addAddress/' + $route.params.departmentId + '/' + $route.params.doctorId + '/0'">
            <template #top>
              <van-icon name="add-o" style="font-size: 24px;position: relative;top: 6px;"/>
              <span style="margin-left: 8px;">添加新地址</span>
            </template>
          </ListItem>
          <ListItem>
            <template #top>
              <van-radio name="0">来院自取</van-radio>
            </template>
          </ListItem>
        </List>
      </van-radio-group>

      <template v-if="$route.params.departmentId == 0">
        <van-button type="primary" block @click="setDefaultAddress()" style="margin-bottom: 10px;">设为默认地址
        </van-button>
        <van-button type="primary" block @click="deleteAddress()">删除地址</van-button>
      </template>
      <van-button v-else type="primary" block @click="submit()">确认</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';
import {getUserAddress, deleteUserAddress, setDefaultAddress} from '@/api/user.js';
import {commitApplyBill} from '@/api/hospital.js';

export default {
  components: {
    PageTitle,
    List,
    ListItem
  },
  data() {
    return {
      address: '0',
      userAddress: []
    }
  },
  methods: {
    setDefaultAddress() {
      setDefaultAddress(this.$store.getters.userInfo.UserId, this.address).then(
          () => {
            location.reload();
          }, () => {
          }
      )
    },
    deleteAddress() {
      if (this.address == 0) {
        this.$toast('来院自取 方式无法删除');
        return;
      }

      this.$dialog.confirm({
        title: '确认操作',
        message: '是否确认删除操作',
        confirmButtonText: '取消',
        cancelButtonText: '确定'
      }).then(() => {
        // on confirm
      }).catch(() => {
        // on cancel
        deleteUserAddress(this.address).then(
            () => {
              location.reload();
            }, () => {
            }
        )
      });
    },
    submit() {
      let that = this;
      //提交订单
      function submitBill(postData, that) {
        postData.appointTime = that.$util.moment(postData.appointTime).utcOffset(8).format('YYYY-MM-DD HH:mm:ss');
        commitApplyBill(postData).then(
            (data) => {
              postData.doctorId = that.$route.params.doctorId;
              postData.departmentId = that.$route.params.departmentId;
              postData.jsdm = data;
              postData.certno = JSON.parse(localStorage.getItem('pageTempData')).certno;
              // console.log(postData.certno);
              that.$store.dispatch('pageData/clearData');
              my.navigateTo({ url: '/pages/Settlement/Settlement?type=appointment&param=' + JSON.stringify(postData) });
              // that.$router.push('/consultOnline/2/' + that.$route.params.departmentId + '/' + that.$route.params.doctorId);
            }, (errMsg) => {
              postData.errMsg = errMsg;
              that.$store.dispatch('pageData/setData', postData);
              that.$router.push('/consultOnline/3/' + that.$route.params.departmentId + '/' + that.$route.params.doctorId);
            }
        );
      }

      if (this.$route.params.departmentId != '-1') {
        let currentAddress = this.userAddress.find((currentValue) => {
          return currentValue.id == this.address;
        });
        let postData = this.$store.getters.temp;
        postData.applyType = localStorage.getItem('applyType') || '云门诊';
        if (currentAddress) {
          postData.sjr_Name = currentAddress.addressee;
          postData.sjr_Phone = currentAddress.phone;
          postData.sjr_Province = currentAddress.province;
          postData.sjr_City = currentAddress.city;
          postData.sjr_Area = currentAddress.area;
          postData.sjr_Street = currentAddress.street;
          postData.sjr_SpecificAddress = currentAddress.specificAddress;

          submitBill(postData, this);
        } else {
          postData.sjr_Name = '来院自取';
          this.$dialog.confirm({
            title: '确认操作',
            message: '请确认取药方式为:“来院自取”',
            confirmButtonText: '取消',
            cancelButtonText: '确定'
          }).then(() => {
            // on confirm
            return;
          }).catch(() => {
            // on cancel
            submitBill(postData, this);
          });
        }
      } else {
        let currentAddress = this.userAddress.find((currentValue) => {
          return currentValue.id == this.address;
        });
        if (this.address != 0) {
          this.$router.push('/encore/' + this.$route.params.doctorId + '/' + currentAddress.addressee + '/' + currentAddress.province + '/' + currentAddress.city + '/' + currentAddress.area + '/' + currentAddress.street + '/' + currentAddress.specificAddress + '/' + currentAddress.phone);
        } else {
          this.$router.push('/encore/' + this.$route.params.doctorId + '/' + '来院自取' + '/' + '来院自取' + '/' + '来院自取' + '/' + '来院自取' + '/' + '来院自取' + '/' + '来院自取' + '/' + '来院自取');
        }
      }
    },
  },
  mounted() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appx/web-view.min.js';
    document.body.appendChild(script);
  },
  beforeCreate() {
    getUserAddress(this.$store.getters.userInfo.UserId).then(
        (result) => {
          this.userAddress = result || [];
          if (this.userAddress.length) this.address = (result[0].isdefault ? result[0].id : '0');
        }, () => {
        });
  }
}
</script>

<style scoped="" lang="less">
@import "../style/var.less";

.infoContainer {
  padding: 10px 15px;
  vertical-align: middle;
  position: relative;

  .left {
    p {
      margin: 5px 0;
      font-size: @font-size-sm;
      line-height: 14px;
      text-align: left;

      b {
        font-size: @font-size-lg;
      }
    }

    .van-icon {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .right {
    img {
      position: absolute;
      right: -15px;
      top: -10px;
      width: 70px;
    }

    .van-icon {
      top: 25px;
      font-size: 24px;
    }
  }
}

.cardInfo {
  font-size: @font-size-sm;

  span {
    margin: 3px;

    a {
      color: @green;
    }
  }

  .van-icon {
    position: relative;
    top: 4px;
  }
}
</style>
